import React, { useState}  from 'react'
import Logo_img from '../icons/logo.png'
import vs_img from '../icons/vs1.png'
import card_img from '../icons/card1.png'
import monster_img from '../icons/monster.png'
import battle_img from '../icons/battle.png'
import bonus_img from '../icons/bonus.png'
import community_img from '../icons/community.png'
import right_img from '../icons/right.png'
import down_img from '../icons/down.png'
import up_img from '../icons/up.png'
import twitter_img from '../icons/twitter.png'
import github_img from '../icons/github.png'
import mirror_img from '../icons/mirror.png'
import '../styles/home.css'

const Home = () => {

    const [faq1, setFaq1] = useState(false);
    const [faq2, setFaq2] = useState(false);
    const [faq3, setFaq3] = useState(false);
    const [faq4, setFaq4] = useState(false);
    const [faq5, setFaq5] = useState(false);
    const [faq6, setFaq6] = useState(false);
    const [faq7, setFaq7] = useState(false);
    const [faq8, setFaq8] = useState(false);
    const [faq9, setFaq9] = useState(false);
    const [faq10, setFaq10] = useState(false);

    function redirectToPage() {
        window.open('https://app.constellation.fun', '_blank');
    }
    // function handerClick1(){
    //     setFaq1(!faq1);
        
    // }
    // function handerClick2(){
    //     setFaq2(!faq2);
    // }
    // function handerClick3(){
    //     setFaq3(!faq3);
    // }
    // function handerClick4(){
    //     setFaq4(!faq4);
    // }
    // function handerClick5(){
    //     setFaq5(!faq5);
    // }
    // function handerClick6(){
    //     setFaq6(!faq6);
    // }
    // function handerClick7(){
    //     setFaq7(!faq7);
    // }
    // function handerClick8(){
    //     setFaq8(!faq8);
    // }
    // function handerClick9(){
    //     setFaq9(!faq9);
    // }
    function handerClick(data){
        if(data == 1){
            setFaq1(!faq1);
        }else if(data == 2){
            setFaq2(!faq2);
        }else if(data == 3){
            setFaq3(!faq3);
        }else if(data == 4){
            setFaq4(!faq4);
        }else if(data == 5){
            setFaq5(!faq5);
        }else if(data == 6){
            setFaq6(!faq6);
        }else if(data == 7){
            setFaq7(!faq7);
        }else if(data == 8){
            setFaq8(!faq8);
        }else if(data == 9){
            setFaq9(!faq9);
        }else if(data == 10){
            setFaq10(!faq10);
        }
    }


return (
    <div className='home_parent'>
      <div className='home_title'>
        <img src={Logo_img}/>
        <li>Constellation Battle</li>
      </div>
      <div className='home_content'>
        <div className='home_right'>
            <img className='home_left_img_title' src={card_img}/>
        </div>
        <div className='home_left'>
            <div className='home_left_title'><li>Constellation Battle</li></div>
            <div className='home_left_content_title'><li>One Community-Interactive Play-to-Earn leisure, entertainment, feed and combat full-chain game based on Fuel Network.</li></div>
            {/* <div className='home_left_app' ><li onClick={()=>redirectToPage()}>Launch App</li><img src={goright}/></div>          */}
            <div className='home_left_app' ><li onClick={()=>redirectToPage()}>LAUNCH APP</li></div> 
        </div>
        
      </div>
      <div className='home_content'>
        <div className='home_left'>
            <div className='home_left_title_section'><img src={right_img}/><li>MONSTER</li></div>
            <div className='home_left_content'><li>After minting a monster, the monster,will produce one constellation card every day. The monster has its own genes and weights. The genes and weights determine the probability of winning or losing in a card battle and the weight of the bonus pool.</li></div>       
        </div>
        <div className='home_right'>
            <img className='home_right_img' src={monster_img}/>
        </div>
      </div>
      <div className='home_content'>
         <div className='home_right'>
            <img className='home_right_img' src={battle_img}/>
        </div>
        <div className='home_left'>
            <div className='home_left_title_section'><img src={right_img}/><li>BATTLE</li></div>
            <div className='home_left_content'><li>In the battle section, list the constellation cards you want to play. You can also play directly against the constellation cards listed by other users. The winner gets the card and the loser gets the token.</li></div>
                  
        </div>
        
      </div>
      <div className='home_content'>
        <div className='home_left'>
            <div className='home_left_title_section'><img src={right_img}/><li>BONUS</li></div>
            <div className='home_left_content'><li>In one epoch, any user who combines one or more Zodiac cards can enter the combine bonus pool for rewards. In one epoch, any user who invites other users to participate in the game can enter the invite bonus pool for rewards.</li></div>
                   
        </div>
        <div className='home_right'>
            <img className='home_right_img' src={bonus_img}/>
        </div>
      </div>
      <div className='home_content'>
        <div className='home_right'>
            <img className='home_right_img' src={community_img}/>
        </div>
        <div className='home_left'>
            <div className='home_left_title_section'><img src={right_img}/><li>COMMUNITY</li></div>
            <div className='home_left_content'><li>After any user becomes a community node, he or she can invite members to become members of his or her community. The performance of community members will become the measurement indicator of the community node to determine the reward of community bonus and the outcome of community battles.</li></div>
                     
        </div>
        
      </div>
      <div className='home_faq'>
        <div className='home_faq_title'>
            <li>FAQ</li>
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(1)}>
                <li>What is constellation battle?</li>
                {faq1 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq1 && <div className='faq_contain_content'>
                <li>According to legend, in a distant era, there was a monster that produced only one constellation card every day. Our game--Constellation Battle Game comes from this ancient legend.
                <br/><br/>Users need to mint one monster first. Each monster has its own unique Gene and Weight Value. The Gene is related to the Battle while the Weight Value is related to the Bonus. Once the monster is minted, it has a TOD(time to death) . Before the TOD expires, you need to feed it with one kind of fruit to extend its life. Otherwise it will be in death and won't spit out constellation cards until you revive the monster. The monster randomly produces one certain type of constellation card every day. The type is based on its own genes and random numbers. 
<br/><br/>Users can list duplicate constellation cards in the battle market. Other users can battle against these constellation cards with their own. The winner in battle will get 2 constellation cards. In one epoch of the game , once 12 types of constellation cards are all collected, users can combine a super card(Zodiac Card), holding which users are eligible to enter the bonus pool and get rewards.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(2)}>
                <li>What is lucky wheel?</li>
                {faq2 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq2 && <div className='faq_contain_content'>
                <li>Users can spin the lucky wheel once every 24 hours by spending 50 CON to randomly get apples, bananas, accelerator cards for 8 hours, accelerator cards for 16 hours, accelerator cards for 24 hours, or universal constellation cards (which can be converted into any type of constellation cards, up to 100 cards per epoch).
</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(3)}>
                <li>What determines the outcome of a battle?</li>
                {faq3 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq3 && <div className='faq_contain_content'>
                <li>The outcome of the battle is related to the Gene of both parties and random numbers. The winning/losing ratio is determined by the Weight Value of both parties. 
<br/>For example,if A has weight 8, and B has weight 2, then A has an 80% chance of winning, while B has a 20% chance of winning.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(4)}>
                <li>Can I regenerate my gene and weight?</li>
                {faq4 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq4 && <div className='faq_contain_content'>
                <li>Yes, if you are not satisfied with the monster's Gene and Weight Value, you can regenerate them every 24 hours.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(5)}>
                <li>What can I gain if I lose the battle?</li>
                {faq5 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq5 && <div className='faq_contain_content'>
                <li>List Card parties need to pay 90 CON as a deposit and Battle parties need to pay 100 CON as a deposit. The winner gets the cards, and the loser gets the CON (10% handling fee will be deducted, and the actual amount is 90/81CON).</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(6)}>
                <li>What is the point for?</li>
                {faq6 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq6 && <div className='faq_contain_content'>
                <li>The point is the standard for subsequent airdrops or other rewards.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(7)}>
                <li>How can I get more points?</li>
                {faq7 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq7 && <div className='faq_contain_content'>
                <li>You can get 10 points by combining constellation cards to a super card, and 1 point in battle (battle or be battled against). The more you combine or battle, the more points you can get.
                <br/><br/>Or you can invite more user and become community node, inviter can get 2 points and community node can get 1 point when community user who invited by inviter combining constellation cards to a super card.
                </li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(8)}>
                <li>How all the bonus is distributed?</li>
                {faq8 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq8 && <div className='faq_contain_content'>
                <li>All revenue from the application is divided into three parts: 10% is given to developers for subsequent development and system maintenance, 30% is distributed into the airdrop pool for subsequent airdrops or other rewards, 30% is distributed into the bonus Combine pool to reward those users who combine constellation cards to super cards(Zodiac Cards), 
                    10% is distributed into the bonus Invite pool to reward those users who Invite other users, and 20% is distributed into the bonus Community pool to reward Community Node.

<br/><br/>The reward algorithm of the bonus pool is: 20% of the total remaining in the previous round + 50% of the current round.
<br/><br/>For example, if user A has the weight value 8 when entering the bonus Combine pool, while the total weight of the bonus Combine pool is 1000, and the amount that can be distributed by the bonus Combine pool is 10ETH, then user A can get (8/1000)*10 ETH, which is about 0.08ETH;
<br/>If user A Invited 8 users during current epoch, while the total Inivtes of this current epoch is 1000, and the amount that can be distributed by the bonus Invite pool is 10ETH, then user A can get (8/1000)*10 ETH, which is about 0.08ETH; 
<br/>If Node A has the CPoints 8 this epoch, while the total CPoints of this epoch is 1000, and the amount that can be distributed by the bonus Community pool is 10ETH, then Node A can get (8/1000)*10 ETH, which is about 0.08ETH.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={()=>handerClick(9)}>
                <li>How can I get more CPoints?</li>
                {faq9 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq9 && <div className='faq_contain_content'>
                <li>First you should become community node, then you should invite user become your community member(any user who invited by your invite url or your member user invite url will become your community member).
                    <br/><br/>You can get 10 CPoints from each community member user who combining constellation cards to a super card, and get 1 CPoint when community member battle (battle or be battled against). The more your community member combine or battle, the more CPoints you can get.</li>
            </div>}
        </div>
        <div className='faq_contain_bottom'>
            <div className='faq_contain_title' onClick={()=>handerClick(10)}>
                <li>Why get less CON when swap than in battle?</li>
                {faq10 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq10 && <div className='faq_contain_content'>
                <li>We encourage users to participate in battles, but also allow users to directly exchange constellation card for CON.</li>
            </div>}
        </div>
      </div>
      <div className='home_social'>
        <a href='https://x.com/constellationba' target="_blank"><img src={twitter_img}/></a>
        <a href='https://github.com/constellationbattle' target="_blank"><img src={github_img}/></a>
        <a href='https://mirror.xyz/0x506CF76Ab79B1E777BB64C173ABbE422Aafe5F01' target="_blank"><img src={mirror_img}/></a>
      </div>
      <div className='home_bottom'>
         <li>@2024 Constellation Battle Team. All rights reserved</li>
      </div>
    </div>
    

    )
}

export default Home